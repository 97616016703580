/**
 * Mortgage Calculator
 *
 * You can changes the default values in config
 *
 * DEV:NOTES
 * Please do not do nay changes in the DO NOT DO ANY CHNAGES block
 * Do import in use project specific import here
 * And play with the render layout structure and keep the params
 */

 import React, { useState, useEffect } from "react"
 import PropTypes from "prop-types"
 import { Container, Row, Col, Button, Accordion, Tabs, Tab, Form, Card } from 'react-bootstrap';
 import { filterNumber, numberFormat, pmt } from "./util"
 import { defaultValues } from "./config"
 import "../../ProductDetails/PaymentDetails/PaymentDetails.scss";
import ScrollAnimation from "react-animate-on-scroll"
import img01 from '../../../images/product-details/toby.jpg';
import { Link } from "@StarberryUtils";

import { calculateMonthlyPayment } from "./calculator"
 // use project specific import here
 
 // User project specific const
 const durationOptions = [10, 15, 20, 25, 30, 35]
 
 // DO NOT DO ANY CHNAGES - START
 const MortgageCalc = props => {
    const {
        propertyPrice,
        depositAmount,
        loadDuration,
        interestRate,
        currency,
        title,
        defaultResult,
        pricePrefix,
        // instantResult,
        // durationSuffix,
        // yearSuffix,
        // yearSelect,
    } = props;
    const [monthlyPayment, setMonthlyPayment] = useState(0);
    const prefix = pricePrefix ? currency : ""
    const [validated, setValidated] = useState(false)
    const [showerror, setShowerror] = useState(false)
    const [purchasePrice, setPurchasePrice] = useState(
        prefix + numberFormat(propertyPrice)
    )
    const [deposit, setDeposit] = useState(prefix + numberFormat(depositAmount))
    const [duration, setDuration] = useState(loadDuration)
    const [interest, setInterest] = useState(interestRate)
    const [loan, setLoan] = useState("")
    const [result, setResult] = useState("")

    const submitRef = React.useRef(null);

    const advisor_img  ="https://ggfx-bovingdons.s3.eu-west-2.amazonaws.com/i.prod/Danny_Antonio_for_Website_7e161910be.jpg"
    useEffect(() => {
        setMonthlyPayment(
            calculateMonthlyPayment(
                parseInt(filterNumber(purchasePrice)),
                parseFloat(interest),
                parseInt(filterNumber(deposit)),
                duration
            )
        );
    }, [])
    
    const handlePrice = event => {
        let val = filterNumber(event.target.value);
        setPurchasePrice(prefix + numberFormat(val))
        if (parseInt(filterNumber(deposit)) && parseInt(val)) {
            let loan = parseInt(val) - parseInt(filterNumber(deposit))
            setLoan(prefix + numberFormat(loan))
        } else {
            setLoan(prefix + 0)
        }
    }
    
    const handleDeposit = event => {
        let d = filterNumber(event.target.value.replace(/[^\d.]/g, ""))
        if(d < filterNumber(purchasePrice)) {
            setDeposit(prefix + numberFormat(d))
        
            if (parseInt(filterNumber(purchasePrice)) && parseInt(d)) {
                let loan2 = parseInt(filterNumber(purchasePrice)) - parseInt(d)
                setLoan(prefix + numberFormat(loan2))
            } else {
                setLoan(prefix + 0)
            }
        }
    }
    
    const handleDuration = event => {
        setDuration(filterNumber(event.target.value))
    }
    
    const handleInterest = event => {
        setInterest(event.target.value.replace(/[^\d.]/g, ""))
    }
    
    const handleLoan = event => {
        setLoan(prefix + numberFormat(filterNumber(event.target.value)))
    }
    
    const getResult = (interest, duration, loan) => {
        let result = -pmt(
            interest / 100 / 12,
            filterNumber(duration) * 12,
            filterNumber(loan),
            0,
            1
        )
        setResult(numberFormat(Math.round(result)))
    }
    
    const handleSubmit = event => {
        const form = event.currentTarget
        event.preventDefault()
        event.stopPropagation()
        if (form.checkValidity() === false) {
            setShowerror(true)
            setValidated(true)
        } 
        else {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'Calculator',
                'formType': title,
                'formId': "Mortgage Calculator",
                'formName': "Mortgage Calculator",
                'formLabel': "Mortgage Calculator"
            });
            setShowerror(false)
            setValidated(false)
            if (
                parseInt(filterNumber(purchasePrice)) &&
                parseInt(filterNumber(deposit))
            ) {
                let loan =
                parseInt(filterNumber(purchasePrice)) -
                parseInt(filterNumber(deposit))
                setLoan(prefix + numberFormat(loan))
                getResult(interest, duration, loan)

                setMonthlyPayment(
                    calculateMonthlyPayment(
                        parseInt(filterNumber(purchasePrice)),
                        parseFloat(interest),
                        parseInt(filterNumber(deposit)),
                        duration
                    )
                );
          
            }
        }
    }


    
    useEffect(() => {
        if (defaultResult) {
            if (parseInt(filterNumber(purchasePrice)) && parseInt(filterNumber(deposit))) {
                let loan =
                parseInt(filterNumber(purchasePrice)) -
                parseInt(filterNumber(deposit))
                setLoan(prefix + numberFormat(loan))
            }
            getResult(interest, duration, loan)
        }
    }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix])
    
    // DO NOT DO ANY CHNAGES - END
    useEffect(() => {
        submitRef.current.click()
    }, [defaultResult, purchasePrice, deposit, loan, interest, duration, prefix]);

    var monthly_payment = Math.round(monthlyPayment);

    return (
         <section className="payment">
         <ScrollAnimation animateIn='fadeInUp'>
             <Container>
                 <Row className="justify-content-center">
                     <Col md={12} xl={11} className="col-xxl-11">
                         <div className="payment-details">
                             <span className="sm-content">Finance</span>
                             <h3>Calculate your Mortgage Repayments</h3>
                             <div className="payment-wrapper">
                                
                                 <div className="form-data">
                                    <Form className="form-field" noValidate validated={validated} onSubmit={handleSubmit}>
                                        <input type="hidden" name="bot-field" />
                                            <Form.Group>
                                                <Form.Label>property value</Form.Label>
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="4750000" 
                                                    required
                                                    name="purchase_price"
                                                    value={purchasePrice}
                                                    onChange={handlePrice}  
                                                />
                                                {/* <span>
                                                    &#163;
                                                        </span> */}
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Interest rate (%)</Form.Label>
                                                <Form.Control 
                                                    required
                                                    type="text"
                                                    name="interest_rate"
                                                    value={interest}
                                                    placeholder="Annual Interest (%)"
                                                    onChange={handleInterest}
                                                />

                                            </Form.Group>
                                            
                                            <Form.Group controlId="deposit">
                                                <Form.Label>deposit amount</Form.Label>
                                                <Form.Control 
                                                    required
                                                    type="text"
                                                    name="deposit_available"
                                                    placeholder="Deposit"
                                                    value={deposit}
                                                    onChange={handleDeposit}
                                                />
                                                {/* <span>
                                                    &#163;
                                                        </span> */}
                                            </Form.Group>
                                            
                                            <Form.Group controlId="duration">
                                                <Form.Label>term (years)</Form.Label>
                                                <Form.Control 
                                                    as="select"
                                                    className="form-control"
                                                    required
                                                    name="duration"
                                                    value={duration}
                                                    placeholder="Duration (Years)"
                                                    onChange={handleDuration}
                                                >
                                                    {durationOptions.map((value, key) => {
                                                    return (
                                                        <option value={value} key={key}>
                                                        {value} Years
                                                        </option>
                                                    )
                                                    })}
                                                </Form.Control>
                                            </Form.Group>
                                            <Button className="btn btn-pink d-none" type="submit" ref={submitRef}>
                                                Calculate
                                            </Button>
                                        </Form>
                                     {result && <div className="payments">
                                         <span className="re-payment">
                                             monthly repayment
                                         </span>
                                         <span className="price">
                                            {currency} {numberFormat(monthly_payment)}
                                            {/* {result} */}
                                        </span>
                                     </div>}
                                 </div>
                                 {showerror && (
                                    <div className="alert-error pl-5 mb-3">
                                    <p>* All fields are required</p>
                                    </div>
                                )}
                                 <div className="our-advisors test">
                                     <h5 className="my-xl-0">Speak with our Mortgage Advisors</h5>
                                     <div className="author-info my-0">
                                         {/* <div className="images">
                                             <div className="author-img">
                                                 <figure>
                                                     <img src={img} alt="img" />
                                                 </figure>
                                             </div>
                                             <div className="about-img">
                                                 <h4>Kieran Hayes</h4>
                                                 <a href="tel:+07989563350" className="tel">07989 563 350</a>
                                             </div>
                                         </div> */}
                                          <div className="images my-0">
                                             <div className="author-img">
                                                 <figure>
                                                     <img src={advisor_img} alt="img" />
                                                 </figure>
                                             </div>
                                             <div className="about-img">
                                                 <h4>Danny Antonio</h4>
                                                 <a href="tel:+07503744027" className="tel pt-0">07503 744 027</a>
                                             </div>
                                         </div>
                                         <div className="images ar-link my-0">
                                             <div className="about-img">
                                                <Link className="pt-0 text-underline" href="/mortgage/"><u>Find out more {">"}</u></Link>
                                             </div>
                                         </div>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </Col>
                 </Row>
             </Container>
         </ScrollAnimation>
     </section>
    )
}
 
 MortgageCalc.defaultProps = {
   propertyPrice: defaultValues.PROPERTY_VALUE,
   depositAmount: defaultValues.DEPOSIT,
   loadDuration: defaultValues.DURATION,
   interestRate: defaultValues.INTEREST,
   currency: process.env.CURRENCY
     ? process.env.CURRENCY
     : defaultValues.CURRENCY,
   defaultResult: defaultValues.DEFAULT_RESULT,
   pricePrefix: defaultValues.PRICE_PREFIX,
   // instantResult: defaultValues.INSTANT_RESULT,
   // durationSuffix: defaultValues.DURATION_SUFFIX,
   // yearSuffix: defaultValues.YEAR_SUFFIX,
   // yearSelect: defaultValues.YEAR_SELECT,
 }
 
 MortgageCalc.propTypes = {
   propertyPrice: PropTypes.any,
   depositAmount: PropTypes.any,
   loadDuration: PropTypes.any,
   interestRate: PropTypes.any,
   currency: PropTypes.string,
   defaultResult: PropTypes.any,
   pricePrefix: PropTypes.any,
   // instantResult: PropTypes.any,
   // durationSuffix: PropTypes.any,
   // yearSuffix: PropTypes.any,
   // yearSelect: PropTypes.any
 }
 
 export default MortgageCalc;